var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'flex m-auto text-black bg-gray-100 force-w-screen',
		_vm.compactCollapsed ? 'min-h-13 md:min-h-98' : 'min-h-98'
	],attrs:{"id":_vm.category.name}},[_c('div',{class:[
			'relative w-full max-w-2xl lg:max-w-4xl md:mx-auto',
			_vm.compactMobileView ? 'pt-3 md:pt-6' : 'pt-6'
		]},[_c('div',{ref:_vm.category.id,staticClass:"absolute top-0 -mt-24",attrs:{"id":_vm.category.id}}),_vm._v(" "),_vm._t("header",function(){return [_c('div',{class:[
					'px-4 text-xl font-bold text-left uppercase md:px-4 xl:px-1.5',
					{ 'pb-3 md:pb-0': _vm.compactCollapsed }
				]},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.category.name)+"\n\t\t\t\t"),(_vm.hasMoreCards || _vm.compactMobileView)?_c('button',{class:[
						'flex items-center px-2 -mx-2 space-x-1.5 text-green-500 rounded-lg outline-none text-base focus:ring ring-green-300',
						{ 'md:hidden': _vm.compactMobileView && !_vm.hasMoreCards }
					],on:{"click":function($event){return _vm.toggleAccordion()}}},[_c('div',{staticClass:"text-center uppercase"},[_vm._t("title",function(){return [_vm._v("\n\t\t\t\t\t\t\tView all "),_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.category.totalCount)+"\n\t\t\t\t\t\t\t")]),_vm._v("Locations\n\t\t\t\t\t\t")]})],2),_vm._v(" "),_c('ArrowDown',{class:[
							'mx-auto text-green-500',
							'transition-all ease-in delay-75',
							{ 'transform rotate-180': _vm.isExpanded }
						]})],1):_vm._e()])]},{"toggleAccordion":_vm.toggleAccordion,"hasMoreCards":_vm.hasMoreCards,"compactMobileView":_vm.compactMobileView,"compactCollapsed":_vm.compactCollapsed,"isExpanded":_vm.isExpanded}),_vm._v(" "),_c('div',[(_vm.hasItems)?_c('TransitionGroup',{ref:"accordion-items-list",class:[
					'px-4 pt-6 pb-8 xl:px-1.5 transition-all ease-in duration-200 overflow-y-hidden',
					_vm.isExpanded ? _vm.expandedDisplayClasses : _vm.collapsedDisplayClasses,
					{ 'hidden md:flex' : _vm.compactCollapsed }
				],attrs:{"name":"list-slide-up","tag":"ul"}},[_vm._t("items",null,{"page":_vm.page,"perPage":_vm.itemsPerPage}),_vm._v(" "),_vm._t("loading",function(){return [(_vm.showLoadingSpinner)?_c('LoadingSpinner',{key:"loading-spinner",staticClass:"w-32 m-auto text-gray-300 md:py-20"}):_vm._e()]},{"hideLoadingSpinner":_vm.hideLoadingSpinner,"loading":_vm.loading,"isExpanded":_vm.isExpanded})],2):_vm._e()],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }