<template>
	<div
		:id="category.name"
		:class="[
			'flex m-auto text-black bg-gray-100 force-w-screen',
			compactCollapsed ? 'min-h-13 md:min-h-98' : 'min-h-98'
		]"
	>
		<div
			:class="[
				'relative w-full max-w-2xl lg:max-w-4xl md:mx-auto',
				compactMobileView ? 'pt-3 md:pt-6' : 'pt-6'
			]"
		>
			<div
				:id="category.id"
				:ref="category.id"
				class="absolute top-0 -mt-24"
			/>
			<slot
				name="header"
				:toggle-accordion="toggleAccordion"
				:has-more-cards="hasMoreCards"
				:compact-mobile-view="compactMobileView"
				:compact-collapsed="compactCollapsed"
				:is-expanded="isExpanded"
			>
				<div
					:class="[
						'px-4 text-xl font-bold text-left uppercase md:px-4 xl:px-1.5',
						{ 'pb-3 md:pb-0': compactCollapsed }
					]"
				>
					{{ category.name }}
					<button
						v-if="hasMoreCards || compactMobileView"
						:class="[
							'flex items-center px-2 -mx-2 space-x-1.5 text-green-500 rounded-lg outline-none text-base focus:ring ring-green-300',
							{ 'md:hidden': compactMobileView && !hasMoreCards }
						]"
						@click="toggleAccordion()"
					>
						<div class="text-center uppercase">
							<slot name="title">
								View all <span>
									{{ category.totalCount }}
								</span>Locations
							</slot>
						</div>

						<ArrowDown
							:class="[
								'mx-auto text-green-500',
								'transition-all ease-in delay-75',
								{ 'transform rotate-180': isExpanded }
							]"
						/>
					</button>
				</div>
			</slot>
			<div>
				<TransitionGroup
					v-if="hasItems"
					ref="accordion-items-list"
					name="list-slide-up"
					tag="ul"
					:class="[
						'px-4 pt-6 pb-8 xl:px-1.5 transition-all ease-in duration-200 overflow-y-hidden',
						isExpanded ? expandedDisplayClasses : collapsedDisplayClasses,
						{ 'hidden md:flex' : compactCollapsed }
					]"
				>
					<slot
						name="items"
						:page="page"
						:per-page="itemsPerPage"
					/>
					<slot
						name="loading"
						:hide-loading-spinner="hideLoadingSpinner"
						:loading="loading"
						:is-expanded="isExpanded"
					>
						<LoadingSpinner
							v-if="showLoadingSpinner"
							key="loading-spinner"
							class="w-32 m-auto text-gray-300 md:py-20"
						/>
					</slot>
				</TransitionGroup>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		ArrowDown: () => import('@/components/icons/ArrowDown.vue'),
		LoadingSpinner: () => import('@/components/multiUse/LoadingSpinner.vue')
	},
	props: {
		category: {
			type: Object,
			default: () => ({
				expanded: false,
				totalCount: 24,
				id: 1,
				name: 'Title'
			})
		},
		compactMobileView: {
			type: Boolean,
			default: false
		},
		numberOfItemsOnFirstLoad: {
			type: Number,
			default: 4
		},
		items: {
			type: Array,
			required: true
		},
		itemsPerPage: {
			type: Number,
			default: 4
		},
		page: {
			type: Number,
			default: 1
		},
		hideLoadingSpinner: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Number,
			default: 0
		},
		expandedDisplayClasses: {
			type: String,
			default: 'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-4 justify-center xl:justify-start xl:gap-6 overflow-x-hidden'
		},
		collapsedDisplayClasses: {
			type: String,
			default: 'flex flex-row flex-nowrap md:grid md:grid-cols-3 lg:grid-cols-4 justify-start md:justify-center lg:justify-start overflow-x-auto space-x-4 md:gap-4 xl:gap-6 md:space-x-0 md:overflow-x-hidden'
		}
	},
	emits: [
		'accordion-click',
		'next-page'
	],
	data() {
		return {
			observer: null
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		compactCollapsed() {
			return this.compactMobileView && !this.isExpanded
		},
		rowId() {
			return this.category?.id
		},
		isExpanded() {
			return this.category?.expanded
		},
		hasItems() {
			return !!this.items?.length
		},
		hasMoreCards() {
			return this.category?.totalCount > this.numberOfItemsOnFirstLoad
		},
		observerIndex() {
			return this.items?.length - 1 ?? 0
		},
		showLoadingSpinner() {
			return !this.hideLoadingSpinner && this.loading === 1 && this.isExpanded
		}
	},
	watch: {
		items() {
			if (this.page > 1) {
				this.observeCards()
			}
		},
		'category.expanded'(newValue, oldValue) {
			if (!newValue && oldValue && this.isMobile) {
				this.scrollToElement()
			}
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	methods: {
		scrollToElement() {
			try {
				const anchorElement = this.$refs[this.category.id]
				if (anchorElement) {
					this.$nextTick(() => {
						anchorElement.scrollIntoView({ behavior: 'smooth' })
					})
				}
			} catch (e) {
				logError(e)
			}
		},
		toggleAccordion() {
			this.$emit('accordion-click', this.rowId)
		},
		observeCards() {
			this.$nextTick(() => {
				if (window && 'IntersectionObserver' in window) {
					const itemId = `${this.items?.[this.observerIndex]?.id}-${this.category?.name}`
					const scrollTarget = document.getElementById(itemId)
					this.observer = new IntersectionObserver(entries => {
						entries.forEach(entry => {
							if (entry.isIntersecting) {
								this.$emit('next-page', this.rowId)
							}
						})
					})
					this.observer.observe(scrollTarget)
				}
			})
		}
	}
}
</script>

<style scoped>
:deep(.list-slide-up-enter-active),
:deep(.list-slide-up-leave-active) {
	transition: all 0.35s ease;
	transition-delay: calc( 0.05s * var(--index) - ( 0.05s * ((var(--page) - 1) * var(--perPage)) ) );
}
:deep(.list-slide-up-enter),
:deep(.list-slide-up-leave-to) {
	opacity: 0;
	transform: translateY(100px);
	transition: all 0.2s ease;
}
</style>
